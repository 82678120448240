import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "./HomeSearch.css";
import { setLogLevel } from "firebase/app";
import GameDetails from "./GameDetails";

setLogLevel("debug");

function HomeSearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [isExiting, setIsExiting] = useState(false);
  const navigate = useNavigate();
  const adsbygoogleRef = useRef(window.adsbygoogle || []);

  const fetchGames = useCallback(
    async (query) => {
      setLoading(true); 
      try {
        const response = await fetch(
          `https://searchgames-rt2xgvdeua-uc.a.run.app?query=${query}`
        );
        const data = await response.json();
        setGames(data.results || []);
      } catch (error) {
        console.error("Error fetching games:", error);
      } finally {
        setLoading(false); 
      }
    },
    []
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchGames(debouncedSearchTerm);
    } else {
      setGames([]);
    }
  }, [debouncedSearchTerm, fetchGames]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleGameClick = (game) => {
    setIsExiting(true);
    setTimeout(() => {
      navigate(`/game/${game.id}`, { state: { game } });
    }, 250);
  };

  
  useEffect(() => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-XMSJBWEGSQ";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-XMSJBWEGSQ");
    };
  }, []);

  
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      adsbygoogleRef.current.push({});
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const xPercent = (clientX / windowWidth) * 100;
      const yPercent = (clientY / windowHeight) * 100;

      document.body.style.backgroundPosition = `${50 + (xPercent - 50) / 10}% ${
        50 + (yPercent - 50) / 10
      }%`;
    };

    const handleResize = () => {
      if (window.innerWidth > 768) {
        window.addEventListener("mousemove", handleMouseMove);
      } else {
        window.removeEventListener("mousemove", handleMouseMove);
        document.body.style.backgroundPosition = "center"; 
      }
    };

    
    handleResize();

    
    window.addEventListener("resize", handleResize);

    
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-cover bg-center relative">
      <div className="vertical-banner-ad-left">
        <ins
          className="adsbygoogle"
          style={{ display: "block", width: "160px", height: "100vh" }}
          data-ad-client="ca-pub-8043943929503307"
          data-ad-slot="4348228520"
          data-ad-format="auto"
        ></ins>
      </div>
      
      <div className="vertical-banner-ad-right">
        <ins
          className="adsbygoogle"
          style={{ display: "block", width: "160px", height: "100vh" }}
          data-ad-client="ca-pub-8043943929503307"
          data-ad-slot="2127181875"
          data-ad-format="auto"
        ></ins>
      </div>

      <div className="main-content">
        <div
          className="flex flex-col min-h-screen bg-cover bg-center"
          style={{ backgroundImage: "url('../images/background.jpg')" }}
        >
          <div className="absolute support-button">
            <a
              href="https://ko-fi.com/whereplay"
              className="px-4 py-2 bg-purple-400 text-white rounded hover:bg-purple-500 transition-colors"
            >
              💸 Support WherePlay
            </a>
          </div>
          <div className="flex flex-col items-center justify-center flex-grow mb-24">
            <div
              className={`text-8xl mb-4 fade-in ${isExiting ? "ease-out" : ""}`}
            >
              🎮
            </div>
            <h1
              className={`text-6xl font-bold text-gray-300 mb-8 fade-in ${
                isExiting ? "ease-out" : ""
              }`}
            >
              Where
              <span className="text-purple-400">Play</span>
            </h1>
            <div
              className={`relative w-96 fade-in-delayed ${
                isExiting ? "ease-out" : ""
              }`}
            >
              <input
                type="text"
                placeholder="Search for games..."
                value={searchTerm}
                onChange={handleInputChange}
                className="p-3 border border-gray-500 rounded-lg w-full text-gray-300 bg-gray-800"
              />
              <ul className="absolute mt-2 w-full bg-gray-800 text-gray-300 rounded-lg shadow-lg z-10">
                {loading ? (
                  <div className="flex justify-center items-center py-4">
                    <div className="spinner border-t-4 border-purple-400 border-solid rounded-full w-8 h-8 animate-spin"></div>
                  </div>
                ) : (
                  games.map((game) => (
                    <li
                      key={game.id}
                      className="p-2 hover:bg-gray-700 cursor-pointer flex items-center"
                      onClick={() => handleGameClick(game)}
                    >
                      <img
                        src={game.background_image}
                        alt={game.name}
                        className="w-12 h-12 mr-4 rounded"
                      />
                      <span>{game.name}</span>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
          <footer className="text-center text-gray-400 text-sm py-4">
            Powered by{" "}
            <a
              href="https://rawg.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-purple-400 hover:underline"
            >
              RAWG
            </a>
          </footer>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeSearch />} />
        <Route path="/game/:id" element={<GameDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
